import React, { useCallback } from "react"
import { Flex, H2, Loader } from "@ikiru/talentis-fpc"
import {
  FlexItemStyled,
  PersonalInfoWrapper,
  PersonWrapper,
  StyleDiv
} from "./styles"
import { ExperienceSection } from "./ExperienceSection"
import { PersonContacts } from "./PersonContacts"
import { PersonActivities } from "./PersonActivities"
import { PersonNotes } from "./PersonNotes"
import { PersonInfo } from "./PersonInfo"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { getLocalSearchResult } from "views/internal-search/actions"
import { Pagination } from "components/functional/pagination/Pagination"
import { InternalPersonType } from "views/internal-search/InternalSearchModule/types"

const InternalSearchResults = () => {
  const {
    isLoadingSearchResults,
    internalPersons,
    internalSearchPage,
    setInternalPersons,
    setInternalSearchPage,
    filters
  } = useInternalSearch()

  const handlePageChange = useCallback(
    async (page: number) => {
      setInternalSearchPage({ ...internalSearchPage, pageNumber: page })
      let searchResult = await getLocalSearchResult(filters, page)

      setInternalSearchPage({
        hasNextPage: searchResult.hasNextPage,
        hasPreviousPage: searchResult.hasPreviousPage,
        pageCount: searchResult.pageCount,
        pageNumber: searchResult.pageNumber,
        pageSize: searchResult.pageSize,
        totalItemCount: searchResult.totalItemCount,
        maxPages: 9
      })
      setInternalPersons(searchResult.people)
    },
    [filters, internalSearchPage]
  )

  return (
    <>
      {isLoadingSearchResults ? (
        <Loader />
      ) : (
        <>
          <StyleDiv>
            <H2 m="0" color="#224160">
              {internalSearchPage.totalItemCount} results from the TalentGraph
            </H2>
          </StyleDiv>

          {internalPersons?.map((person: InternalPersonType, index: number) => (
            <PersonWrapper key={index}>
              <PersonalInfoWrapper>
                <FlexItemStyled isOfflimits={person?.isOfflimits}>
                  <PersonInfo person={person} />
                </FlexItemStyled>
                <FlexItemStyled>
                  {person.latestExperience && (
                    <ExperienceSection experience={person.latestExperience} />
                  )}
                </FlexItemStyled>

                <FlexItemStyled>
                  <PersonContacts
                    taggedEmails={person?.taggedEmails || []}
                    phones={person?.phones || []}
                  />
                </FlexItemStyled>

                <FlexItemStyled>
                  <PersonActivities assignmentCount={0} campaignCount={3} />
                </FlexItemStyled>
              </PersonalInfoWrapper>

              <PersonNotes bio={person.bio} />
            </PersonWrapper>
          ))}
          <Flex width="100%" flexDirection="column">
            {internalSearchPage.pageCount > 1 && (
              <Flex width="100%" justifyContent="center" mb={20}>
                <Pagination
                  {...internalSearchPage}
                  onPageChange={handlePageChange}
                />
              </Flex>
            )}
          </Flex>
        </>
      )}
    </>
  )
}

export default InternalSearchResults
