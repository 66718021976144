import React, { useCallback } from "react"
import { Flex, AttachmentIcon, Link, Div } from "@ikiru/talentis-fpc"
import { NoteDocuments } from "views/persons/person.types"
import { ModalNames } from "setup/modal/modal.definitions"
import AttachmentModel from "./AttachmentModel"
import { useModal } from "utils/hooks/use-modal"

interface NoteAttachmentsProps {
  documents: NoteDocuments[]
  noteId?: string
}

export const NoteAttachments = ({
  documents,
  noteId
}: NoteAttachmentsProps) => {
  const { open } = useModal()

  const seeDocument = useCallback(
    (documentId: string) => {
      noteId &&
        open(
          ModalNames.EmailAttachments,
          <AttachmentModel noteId={noteId} documentId={documentId} />
        )
    },
    [noteId, open]
  )

  return (
    <Flex flexWrap="wrap" ml="xxs" mt="xxs">
      {documents.map((document) => (
        <Flex key={document.id} mr="11px" alignItems="center">
          <Div>
            <AttachmentIcon />
          </Div>
          <Link
            ml="xxs"
            size="small"
            mb="0"
            onClick={() => seeDocument(document.id)}
          >
            {document.fileName}
          </Link>
        </Flex>
      ))}
    </Flex>
  )
}
