import React, { useEffect, useState } from "react"
import { LinkIcon, WebSitesList } from "./style"
import { personProfileDetailsE2ETargets } from "views/persons/components/person-details/definitions"
import { PersonProfileFields } from "views/persons/pages/manage-person/person-profile.types"
import {
  Div,
  FacebookIcon as Facebook,
  YouTubeTempIcon as YouTube,
  LinkedInIcon as LinkedIn,
  CrunchbaseIcon as Crunchbase,
  XingIcon as Xing,
  ReutersIcon as Reuters,
  BloombergIcon as Bloomberg,
  ZoomInfoIcon as ZoomInfo,
  TwitterIcon as Twitter,
  OwlerIcon as Owler,
  CompaniesHouseIcon as CompaniesHouse,
  colors,
  Flex,
  Link,
  BodyText
} from "@ikiru/talentis-fpc"
import { PersonValue } from "models/LocalPerson/localPerson.definitions"
import { WebSite } from "views/persons/person.types"
import { Nullable } from "tsdef"
import { messages } from "setup/messages/messages"
import { transformLinkText } from "utils/links"
import { e2eTargets } from "../../../constant/definitions"
import { SectionTitle } from "../../../style"

type WebSitesRecordType = {
  webSites: PersonValue<WebSite>[]
  linkedInProfileUrl: Nullable<PersonValue<string>>
}

const mapIcons = {
  linkedIn: <LinkedIn />,
  xing: <Xing />,
  crunchbase: <Crunchbase />,
  reuters: <Reuters />,
  bloomberg: <Bloomberg />,
  facebook: <Facebook />,
  zoomInfo: <ZoomInfo />,
  twitter: <Twitter />,
  x: <Twitter />,
  owler: <Owler />,
  companiesHouse: <CompaniesHouse />,
  youTube: <YouTube />
}

const PersonWebLinksView = ({
  webSites,
  linkedInProfileUrl
}: WebSitesRecordType) => {
  const [notPersonalWebSites, setNotPersonalWebSites] = useState<
    PersonValue<WebSite>[]
  >([])
  const [personalWebSites, setPersonalWebSites] = useState<
    PersonValue<WebSite>[]
  >([])

  useEffect(() => {
    let tempNotPersonalWebSite: PersonValue<WebSite>[] = []
    let tempPersonalWebSite: PersonValue<WebSite>[] = []

    webSites.forEach((item) => {
      const icon =
        item.value.type &&
        item.value.type !== "other" &&
        mapIcons[item.value.type]
      const iconExists = Boolean(icon)
      iconExists
        ? tempNotPersonalWebSite.push(item)
        : tempPersonalWebSite.push(item)
    })
    setNotPersonalWebSites(tempNotPersonalWebSite)
    setPersonalWebSites(tempPersonalWebSite)
  }, [webSites])

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer")
    if (newWindow) newWindow.opener = null
  }

  console.log("liliya personalWebSites", personalWebSites)
  return (
    <>
      {!Boolean(
        webSites.length ||
          linkedInProfileUrl?.value ||
          notPersonalWebSites.length
      ) ? (
        <BodyText m="0px" py="xs" color={colors.grey.standard}>
          {messages.person.personTabSection.noDataTexts.noWebLinks}
        </BodyText>
      ) : (
        <WebSitesList as="ul" data-e2e-target-name={e2eTargets.webSites}>
          <Flex>
            {Boolean(linkedInProfileUrl?.value) && (
              <Div
                as="li"
                mr="xxs"
                width="30px"
                height="30px"
                data-e2e-target-name={
                  personProfileDetailsE2ETargets[
                    PersonProfileFields.LinkedInProfileUrl
                  ]
                }
              >
                <LinkIcon
                  onClick={() => openInNewTab(linkedInProfileUrl?.value || "")}
                >
                  <LinkedIn />
                </LinkIcon>
              </Div>
            )}
            {notPersonalWebSites.map((item, index) => {
              const icon =
                item.value.type &&
                item.value.type !== "other" &&
                mapIcons[item.value.type]
              return (
                <Div
                  as="li"
                  key={`${item?.value}-${index}`}
                  data-e2e-target-name={`${e2eTargets.webSites}-${index}`}
                  mr="xxs"
                  mt="0"
                  width="30px"
                  height="30px"
                >
                  <LinkIcon onClick={() => openInNewTab(item?.value.url)}>
                    {icon}
                  </LinkIcon>
                </Div>
              )
            })}
          </Flex>
          {Boolean(personalWebSites.length) && (
            <Flex flexDirection="column" mt="m">
              <SectionTitle>
                {messages.person.personTabSection.webSites}
              </SectionTitle>
              {personalWebSites.map((webLink) => {
                const validUrl =
                  webLink?.value.url.startsWith("http://") ||
                  webLink?.value.url.startsWith("https://")
                    ? webLink?.value.url
                    : `http://${webLink?.value.url}`
                const faviconUrl = `https://www.google.com/s2/favicons?domain=${
                  new URL(validUrl).hostname
                }`
                return (
                  <Flex alignItems="center">
                    <Div width="18px" height="18px">
                      <img
                        src={faviconUrl}
                        alt="favicon"
                        style={{ marginRight: "10px" }}
                      />
                    </Div>
                    <Link
                      m="none"
                      my="xxs"
                      mx="xxs"
                      href={webLink?.value.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {transformLinkText(webLink?.value.url)}
                    </Link>
                  </Flex>
                )
              })}
            </Flex>
          )}
        </WebSitesList>
      )}
    </>
  )
}

export default PersonWebLinksView
