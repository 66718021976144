import React from "react"
import { Button, Div, Flex, PlusIcon } from "@ikiru/talentis-fpc"
import FilterWrapper from "../FilterWrapper"
import { SearchSectionBox } from "./SearchSectionBox"
import { useAddSectionBox } from "views/internal-search/hooks"
import { InternalFilterType } from "views/internal-search/InternalSearchModule/types"
import { LogicalConnection } from "../LogicalConnection"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { getLocalSearchResult } from "views/internal-search/actions"
import { SECTION_BOXES_LIMIT } from "./consts"

export const SearchSection = () => {
  const { boxes, boxesCount, addNewBox } = useAddSectionBox(
    InternalFilterType.Sections
  )

  const {
    filters,
    setInternalPersons,
    setIsLoadingSearchResults,
    setInternalSearchPage
  } = useInternalSearch()

  const runSearch = async () => {
    setIsLoadingSearchResults(true)
    let searchResult = await getLocalSearchResult(filters)

    setInternalSearchPage({
      hasNextPage: searchResult.hasNextPage,
      hasPreviousPage: searchResult.hasPreviousPage,
      pageCount: searchResult.pageCount,
      pageNumber: searchResult.pageNumber,
      pageSize: searchResult.pageSize,
      totalItemCount: searchResult.totalItemCount,
      maxPages: 9
    })
    setInternalPersons(searchResult.people)
  }

  return (
    <Flex flexDirection="column" width="100%" height="auto">
      {boxes.map((box, index) => (
        <React.Fragment key={box.idBox}>
          <FilterWrapper>
            <SearchSectionBox index={index} />
          </FilterWrapper>
          {boxesCount > 1 && index !== boxesCount - 1 && (
            <Div my="10px">
              <LogicalConnection />
            </Div>
          )}
          {index === boxesCount - 1 && boxesCount < SECTION_BOXES_LIMIT && (
            <Flex my="m">
              <Button
                mode="standard-white"
                size="small"
                //@ts-ignore
                onClick={addNewBox}
                prefix={<PlusIcon width={12} height={12} />}
              >
                Add another set of search criteria
              </Button>
            </Flex>
          )}
        </React.Fragment>
      ))}

      <Div>
        <Button
          my="xs"
          type="submit"
          mode="primary"
          size="small"
          onClick={runSearch}
        >
          Run search
        </Button>
      </Div>
    </Flex>
  )
}
