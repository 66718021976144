import {
  cloneDeep,
  get,
  isBoolean,
  isEmpty,
  noop,
  pickBy,
  set,
  toPath
} from "lodash"
import {
  InternalSearchContextValues,
  InternalSearchState,
  InternalSearchFilters,
  InternalSearchActionTypes
} from "./types"

export const initialInternalSearchState: InternalSearchState = {
  filters: {},
  sections: [],
  isLoadingSearchResults: false,
  internalPersons: [],
  internalSearchPage: {
    hasNextPage: false,
    hasPreviousPage: false,
    maxPages: 25,
    pageCount: 0,
    pageNumber: 0,
    pageSize: 0,
    totalItemCount: 0
  }
}

export const internalSearchReducer = (
  state: InternalSearchState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action

  switch (type) {
    case InternalSearchActionTypes.UpdateFilters:
      const newFilters: InternalSearchFilters = { ...state.filters }

      for (const [filterName, filterValue] of Object.entries(payload)) {
        set(newFilters, filterName, filterValue)
      }

      return {
        ...state,
        filters: pickBy(newFilters, (value) =>
          isBoolean(value) ? !!value : !isEmpty(value)
        )
      }
    case InternalSearchActionTypes.ClearCertainFilters:
      const futureFilters: InternalSearchFilters = cloneDeep(state.filters)

      for (const [filterName] of Object.entries(payload)) {
        const pathArr = toPath(filterName)
        const deleteKey = pathArr.pop()
        if (pathArr.length) {
          get(futureFilters, pathArr)?.splice(deleteKey, 1)
        } else if (deleteKey) {
          delete futureFilters[deleteKey as keyof InternalSearchFilters]
        }
      }

      return {
        ...state,
        filters: pickBy(futureFilters, (value) =>
          isBoolean(value) ? !!value : !isEmpty(value)
        )
      }
    case InternalSearchActionTypes.SetIsLoadingSearchResults:
      return {
        ...state,
        isLoadingSearchResults: payload
      }

    case InternalSearchActionTypes.SetSections:
      return {
        ...state,
        sections: payload
      }
    case InternalSearchActionTypes.SetInternalPersons:
      return {
        ...state,
        internalPersons: payload,
        isLoadingSearchResults: false
      }
    case InternalSearchActionTypes.SetInternalSearchPage: {
      return { ...state, internalSearchPage: { ...payload } }
    }
    default:
      return state
  }
}

export const initialInternalSearchContextValues: InternalSearchContextValues = {
  ...initialInternalSearchState,
  updateFilters: noop,
  setIsLoadingSearchResults: noop,
  setSections: noop,
  setInternalPersons: noop,
  addArrayBasedInternalFilter: noop,
  removeArrayBasedInternalFilter: noop,
  clearArrayBasedFilter: noop,
  clearFilters: noop,
  setInternalSearchPage: noop
}
