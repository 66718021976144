import React from "react"
import { Div, Flex, SmallText } from "@ikiru/talentis-fpc"
import { messages } from "setup/messages/messages"
import { PersonalNoteItemStyled, PersonalNotesWrapper } from "./styles"
import { ShowMore } from "components/ShowMore"

type PersonalNotesProps = {
  bio?: string
  note?: any
}

// TO DO note should be update
export const PersonNotes = ({ bio }: PersonalNotesProps) => {
  return (
    <PersonalNotesWrapper>
      <PersonalNoteItemStyled ml="5px">
        <Div mr="10px">
          <ShowMore
            isSmall
            title={messages.person.bio.title}
            visibleLines={3}
            description={bio}
          />
        </Div>
      </PersonalNoteItemStyled>
      <PersonalNoteItemStyled flexDirection="column">
        <Flex flexDirection="column" alignItems="flex-start">
          <Flex>
            <SmallText fontWeight="600" pr="10px">
              Most recent note:
            </SmallText>
            <SmallText>25/06/2024 12:30 Ilyina Liliya</SmallText>
          </Flex>
          <ShowMore
            visibleLines={3}
            isSmall
            description=" is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book.  is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book."
            hasBackground={false}
          />
        </Flex>
      </PersonalNoteItemStyled>
    </PersonalNotesWrapper>
  )
}
