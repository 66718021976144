import React from "react"
import { messages } from "setup/messages/messages"
import { Button, Flex, H2, H4 } from "@ikiru/talentis-fpc"
import InternalSearchCriteria from "./components/InternalSearchCriteria"
import { Tabs } from "views/companies/components/company-details/components/Tabs/Tabs"
import Tab from "views/companies/components/company-details/components/Tabs/Tab"
import InternalSearchResults from "./components/InternalSearchResults"
import { useInternalSearch } from "./InternalSearchModule/context"

export const InternalSearchPage = () => {
  const { isLoadingSearchResults, filters } = useInternalSearch()

  return (
    <Flex flexDirection="column" width="100%">
      <H2 color="#009BA6">Internal search</H2>
      <Flex justifyContent="space-between" backgroundColor="#224160" p="10px">
        <H4 m="0" color="#FFFFFF">
          Build your search
        </H4>
        <Flex>
          <Button mode="standard-grey-light" size="small" mr="s">
            Load saved search
          </Button>
          <Button mode="standard-grey-light" size="small">
            Save this search
          </Button>
        </Flex>
      </Flex>
      <Tabs>
        <Tab title="Search criteria">
          <InternalSearchCriteria />
        </Tab>

        <Tab title="Results" isActive={isLoadingSearchResults}>
          <InternalSearchResults />
        </Tab>
      </Tabs>
    </Flex>
  )
}
