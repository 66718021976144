import React, { useCallback, useMemo } from "react"
import { PersonNote } from "../types"
import { fetchDocument, generateNoteDate } from "components/NoteItem/helpers"
import { RouterUrl } from "setup/router/routes"
import { Div, Flex, Line, Note } from "@ikiru/talentis-fpc"
import { formatDateTime } from "utils/format-date"
import { messages } from "setup/messages/messages"
import { ModalNames } from "setup/modal/modal.definitions"
import AttachmentModel from "components/PersonRecords/Notes/NoteModal/AttachmentModel"
import { useModal } from "utils/hooks/use-modal"

type PersonNoteItemProps = {
  note: PersonNote
  index: number
  editNote: (note: PersonNote) => void
  buttonEditDataE2ETarget: string
}

const PersonNoteItem = ({
  note,
  index,
  buttonEditDataE2ETarget,
  editNote
}: PersonNoteItemProps) => {
  const {
    noteTitle,
    noteDescription,
    createdBy,
    createdDate,
    updatedBy,
    updatedDate,
    linkAssignment,
    linkCampaign
  } = generateNoteDate(note)
  const { open } = useModal()

  const projectRoute = useMemo(
    () =>
      linkAssignment?.id
        ? `${RouterUrl.AssignmentDetails}/${linkAssignment?.id}`
        : linkCampaign?.id
        ? `${RouterUrl.CampaignDetails}/${linkCampaign?.id}`
        : " ",
    [linkCampaign, linkAssignment]
  )

  const seeDocument = useCallback(
    async (documentId: string) => {
      const document = note.documents?.find((doc) => doc.id === documentId)

      if (document) {
        if (document.fileName) {
          const fileExtension = document.fileName.split(".").pop()

          if (fileExtension) {
            const lowerCaseExtension = fileExtension.toLowerCase()

            if (lowerCaseExtension === "txt") {
              open(
                ModalNames.EmailAttachments,
                <AttachmentModel noteId={note.id} documentId={documentId} />
              )
              return
            } else {
              const fileUrl = await fetchDocument(note.id, documentId)

              if (fileUrl) {
                window.open(fileUrl, "_blank")
              } else {
                console.error("Document URL is undefined or null.")
              }
            }
          } else {
            console.error("File extension is missing.")
          }
        } else {
          console.error("Document fileName is missing.")
        }
      }
    },
    [note.documents, note.id, open]
  )

  return (
    <Div>
      {index !== 0 ? <Line variant="thin" /> : null}
      <Flex my="xs">
        <Note
          title={noteTitle}
          description={noteDescription}
          createdDate={createdDate}
          createdBy={createdBy}
          formattedCreatedDate={formatDateTime(createdDate)}
          formattedUpdatedDate={
            updatedDate ? formatDateTime(updatedDate) : null
          }
          tag={linkAssignment?.name || linkCampaign?.name}
          tagVariant={linkCampaign?.name ? "grey-standard" : undefined}
          updatedBy={updatedBy}
          updatedDate={updatedDate}
          moreButtonLabel={messages.person.note.showFullNote}
          lessButtonLabel={messages.person.note.showLessNote}
          linesNumber={4}
          useArrow
          isHeaderFlexRow
          projectUrl={projectRoute}
          documents={note.documents}
          onDocumentClick={seeDocument}
          editModalButton
          isJustifyContent
          openModal={() => editNote(note)}
        />
      </Flex>
    </Div>
  )
}

export default PersonNoteItem
