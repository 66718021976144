import React, { useEffect, useMemo, useState } from "react"
import { Flex, H6, PlusIcon, CrossIcon } from "@ikiru/talentis-fpc"
import {
  sectionFieldTemplateString,
  sectionFieldConditionTemplateString,
  initialTemplateString,
  FIELD_BOXES_LIMIT,
  sectionFieldSearchValuesTemplateString
} from "./consts"
import FormikSelect from "components/functional/formik/formik-select/FormikSelect"
import { Field } from "views/internal-search/InternalSearchModule/types"
import {
  getLocalSearchReferenceValue,
  getLocalSearchReferenceEnums
} from "views/internal-search/actions"
import { useFormikContext } from "formik"
import { useInternalSearch } from "views/internal-search/InternalSearchModule/context"
import { StyledRemoveButton } from "views/internal-search/InternalSearchModule/style"
import { useAddSectionField } from "views/internal-search/hooks"
import { get } from "lodash"
import { conditionOptions, defaultConditionValue } from "./utils"
import { DateInputFiled } from "./DateInputFiled"
import { InputField } from "./InputField"
import { SelectInputField } from "./SelectInputField"

type SearchFieldBoxProps = {
  index: number
  indexSection: number
  fields: Field[]
  boxesCount: number
}

export const SearchFieldBox = ({
  index,
  indexSection,
  fields,
  boxesCount
}: SearchFieldBoxProps) => {
  const { addNewBox, removeBox } = useAddSectionField(indexSection)

  const [selectedFieldType, setSelectedFieldType] = useState<
    string | undefined
  >()
  const [optionsBoolean, setOptionsBoolean] = useState([])

  const [valueForSelect, setValueForSelect] = useState([])

  const { values, setFieldValue } = useFormikContext()

  const { updateFilters, clearArrayBasedFilter, filters } = useInternalSearch()

  const initialFilterName = initialTemplateString.format(indexSection, index)

  const fieldName = sectionFieldTemplateString.format(indexSection, index)
  const fieldConditionName = sectionFieldConditionTemplateString.format(
    indexSection,
    index
  )

  const fieldSearchValuesName = sectionFieldSearchValuesTemplateString.format(
    indexSection,
    index
  )

  const optionsField = useMemo(
    () =>
      fields.map((field: Field) => ({
        value: field.id,
        label: field.name,
        typeField: field.fieldType
      })),
    [fields]
  )

  const handleFieldChange = async (selectedOption: any) => {
    const selectedFieldFind = fields.find(
      (field) => field.id === selectedOption.target.value
    )

    if (selectedFieldFind) {
      clearArrayBasedFilter(initialFilterName)
    }
    // it should be fixed
    //clearArrayBasedFilter(fieldSearchTextName)
    setSelectedFieldType(selectedFieldFind?.fieldType)
    //@ts-ignore
    setOptionsBoolean(conditionOptions(selectedFieldFind?.fieldType))

    if (selectedFieldFind?.fieldType === "referenced") {
      let referencedValue = await getLocalSearchReferenceValue(
        selectedOption.target.value
      )

      const transformedData = referencedValue.items
        .filter((item: any) => item.value.trim() !== "")
        .map((item: any) => ({
          value: item.id,
          label: item.value
        }))

      setValueForSelect(transformedData)
    } else if (selectedFieldFind?.fieldType === "enum") {
      let referencedEnumValue = await getLocalSearchReferenceEnums(
        selectedOption.target.value
      )

      const referencedEnumValueTemp = referencedEnumValue.values.map(
        (enumValue: any) => ({
          value: enumValue,
          label: enumValue.replace(/([A-Z])/g, " $1").trim()
        })
      )
      setValueForSelect(referencedEnumValueTemp)
    }

    updateFilters({
      [fieldName]: selectedOption.target.value
    })
  }

  const handleConditionChange = (selectedOption: any) => {
    if (["blank", "noBlank"].includes(selectedOption.target.value)) {
      updateFilters({
        [fieldSearchValuesName]: []
      })
    }

    if (
      ["contain", "between", "notBetween", "any", "none", "all"].includes(
        selectedOption.target.value
      )
    ) {
      updateFilters({
        [fieldSearchValuesName]: []
      })
    }

    updateFilters({
      [fieldConditionName]: selectedOption.target.value
    })
  }

  const currentFieldValue = get(filters, fieldName)
  const currentFieldConditionValue = get(filters, fieldConditionName)

  useEffect(() => {
    selectedFieldType &&
      !currentFieldConditionValue &&
      updateFilters({
        [fieldConditionName]: defaultConditionValue(selectedFieldType)
      })

    const fetchSearchReferenceValue = async () => {
      let referencedValue = await getLocalSearchReferenceValue(
        currentFieldValue
      )

      const transformedData = referencedValue.items
        .filter((item: any) => item.value.trim() !== "")
        .map((item: any) => ({
          value: item.id,
          label: item.value
        }))

      setValueForSelect(transformedData)
    }

    if (selectedFieldType === "referenced" && currentFieldValue) {
      fetchSearchReferenceValue()
    }
  }, [selectedFieldType, currentFieldValue])

  useEffect(() => {
    if (currentFieldValue && optionsBoolean?.length === 0) {
      const selectedFieldFind = fields.find(
        (field) => field.id === currentFieldValue
      )
      setSelectedFieldType(selectedFieldFind?.fieldType)
      //@ts-ignore
      setOptionsBoolean(conditionOptions(selectedFieldFind?.fieldType))
    }
  }, [currentFieldValue, fields])

  const isBlank = useMemo(() => {
    const condition =
      //@ts-ignore
      filters?.sections?.[indexSection]?.fields?.[index]?.condition
    return ["blank", "notBlank"].includes(condition)
  }, [filters, indexSection, index])

  return (
    <Flex width="100%" justifyContent="space-between">
      <Flex mb="5px" ml="5px">
        <Flex mx="5px" flexDirection="column" width="250px">
          <FormikSelect
            label="Select a field"
            options={optionsField}
            id={fieldName}
            name={fieldName}
            variant="middle"
            firstOption={{
              label: "Select a field",
              value: ""
            }}
            onChange={(e) => {
              handleFieldChange(e)
              setFieldValue(`${initialFilterName}.value`, "")
            }}
            controlled
            value={currentFieldValue}
          />
        </Flex>
        {optionsBoolean?.length > 0 && (
          <Flex mx="5px" flexDirection="column" width="200px">
            <FormikSelect
              label="Condition"
              options={optionsBoolean}
              id={fieldConditionName}
              name={fieldConditionName}
              variant="middle"
              onChange={(e) => handleConditionChange(e)}
              controlled
              value={currentFieldConditionValue}
            />
          </Flex>
        )}
        {!isBlank && (
          <Flex mx="5px">
            {(selectedFieldType === "string" ||
              selectedFieldType === "number" ||
              selectedFieldType === "document") && (
              <InputField index={index} indexSection={indexSection} />
            )}
            {selectedFieldType === "date" && (
              <DateInputFiled index={index} indexSection={indexSection} />
            )}
            {(selectedFieldType === "referenced" ||
              selectedFieldType === "enum") &&
              valueForSelect.length > 0 && (
                <SelectInputField
                  index={index}
                  indexSection={indexSection}
                  valueForSelect={valueForSelect}
                />
              )}
          </Flex>
        )}
        <H6 color="#4B6A88;" m="0" mt="18px" ml="10px">
          {filters?.sections?.[indexSection]?.condition?.toUpperCase()}
        </H6>
      </Flex>
      {index === boxesCount - 1 && boxesCount < FIELD_BOXES_LIMIT ? (
        <StyledRemoveButton
          mode="standard-white"
          size="action-small"
          mt="10px"
          ml="50px"
          //@ts-ignore
          onClick={addNewBox}
        >
          <PlusIcon />
        </StyledRemoveButton>
      ) : (
        <StyledRemoveButton
          mode="standard-white"
          size="action-small"
          mt="10px"
          ml="50px"
          //@ts-ignore
          onClick={() => {
            clearArrayBasedFilter(initialFilterName)
            removeBox(index)
          }}
        >
          <CrossIcon />
        </StyledRemoveButton>
      )}
    </Flex>
  )
}
